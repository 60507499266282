import React,{ Component } from 'react'

class Home extends Component {

    render(){ 
        return(
            <>
               HOME
            </>
        )
    }

}


export default Home;
