import firebase from 'firebase/app'
import  'firebase/firestore'
import 'firebase/auth';
import 'firebase/storage';
const firebaseConfig = {
  apiKey: "AIzaSyDCq0wuKVh7Sre7-6v5SlxKlM_-xGIS3M8",
  authDomain: "pricticeone.firebaseapp.com",
  projectId: "pricticeone",
  storageBucket: "pricticeone.appspot.com",
  messagingSenderId: "543061765364",
  appId: "1:543061765364:web:d9bf10f264e647f0ff82a1",
  measurementId: "G-0TNV4KL2WQ"
};

firebase.initializeApp(firebaseConfig);
firebase.auth();

export const storage = firebase.storage();
export const storageRef = storage.ref();
export const usersRef = storageRef.child('images/users')

const db = firebase.firestore();
export const firebaseTimestamp = firebase.firestore.FieldValue.serverTimestamp;

export const carsCollection = db.collection('cars');
export const usersCollection = db.collection('users');

// export const siteRef = db.doc('site/business');
export const employeeRef = db.collection('site').doc('employees').collection('admins');


export default firebase;